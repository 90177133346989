import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import { Helmet } from "react-helmet"
import ReactPlayer from 'react-player/lazy'
import { GatsbyImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useInView } from "react-intersection-observer"
import {
  StyledAboutKarinaVideo,
  StyledAboutContent
} from "../../styledComponents/aboutKarina"
import SingleEventRegisterButton from "../../components/EventCards/SingleEventRegisterButton"
import LayoutNoStripe from "../../components/layoutNoStripe"
import {
  StyledAboutLander,
  StyledLanderArticle,
  AboutLanderContent,
  AboutTeacherLanderContent,
  StyledAboutKarinaArticle,
  StyledEventCardLanderSection,
  StyledRegisterButtonWrapper
} from "../../styledComponents/lander"
import {StyledBlockQuote} from "../../styledComponents/base"
import Testimonials from "../../components/testimonials"
import HeroBanner from "../../components/HeroBanner"
import { HeroText, HeroHeader } from "../../styledComponents/heroBanner"
import { StyledAnchorLink } from "../../styledComponents/button"
import HiChiMindfulnessCollectionPromo from "../../components/hiChiPromo"
import Icon from "../../components/icon"
const Lander1 = ({
    data, location
}) => {
  const aboutKarinaContent = data.aboutKarina.content
  const skuFull = {
      sku: data.fullPrice.edges[0].node.id,
      name: data.fullPrice.edges[0].node.product.name,
      price: data.fullPrice.edges[0].node.unit_amount,
      description: data.fullPrice.edges[0].node.product.description,
      currency: data.fullPrice.edges[0].node.currency,
      stripeFullPriceId: data.fullPrice.edges[0].node.id
    }
  
    const skuSale = {
      sku: data.salePrice.edges[0].node.id,
      name: data.salePrice.edges[0].node.product.name,
      price: data.salePrice.edges[0].node.unit_amount,
      description: data.salePrice.edges[0].node.product.description,
      currency: data.salePrice.edges[0].node.currency,
      stripeSalePriceId: data.salePrice.edges[0].node.id
    }
  
  const qtImage = {
    imageData: data.whatsQtImage.localFile?.childImageSharp?.gatsbyImageData,
    alt: data.whatsQtImage?.alt || ``,
    description: data.whatsQtImage?.description || "",
  }

  const backImage = {
    imageData: data.boneAlignmentImage.localFile?.childImageSharp?.gatsbyImageData,
    alt: data.boneAlignmentImage?.alt || ``,
    description: data.boneAlignmentImage?.description || "",
  }

  const drTurnerImage = {
    imageData: data.drRonnieTurnerImage.localFile?.childImageSharp?.gatsbyImageData,
    alt: data.drRonnieTurnerImage?.alt || ``,
    description: data.drRonnieTurnerImage?.description || "",
  }

  const qtBook = {
    imageData: data.qtBookImage.localFile?.childImageSharp?.gatsbyImageData,
    alt: data.qtBookImage?.alt || ``,
    description: data.qtBookImage?.description || "",
  }

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 0,
    rootMargin: '0px 0px 0px 0px'
  });
  
  return (
    <>
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />x
    </Helmet>
    <LayoutNoStripe displayNavBorder isLander location>
      <HeroBanner 
        image={data.heroImage.nodes[0].localFile?.publicURL} 
      >
          <HeroText>
            <HeroHeader isLander="true">
            <strong>Quantum-Touch energy healing will take your ability to help yourself and others to a whole new level - whilst YOU heal!</strong>
            </HeroHeader>
            <StyledBlockQuote>
              <Icon name="singlequote" />
              <p>This is the first course that I’ve completed that heals the practitioner and takes great care of them, so they can help others. Wonderful!</p>
              <cite>Rosena Johnston, UK Clinical Lead Physiotherapist, UK Athletics Disability team for Paralympic games</cite>
            </StyledBlockQuote>
            <AnchorLink to="/lander/omnom-quantum-touch-level-1#register">Register now</AnchorLink>
            <StyledAnchorLink inverse="true" className="learn-more" to="/lander/omnom-quantum-touch-level-1#learn">Learn more</StyledAnchorLink>
          </HeroText>
      </HeroBanner>
     
      <StyledLanderArticle itemScope itemType="http://schema.org/Article" id="learn" isInView={inView}>
        <div className="inview-wrapper" ref={ref}>
          <StyledAboutLander itemProp="articleBody">

            <p>What if it were possible to learn how to balance and align hip bones with energy - by lunchtime on the first day of a healing workshop?</p>
            <p>What if you were able to help yourself, loved ones and clients to reduce and eliminate pain?</p>
            <p>Why are so many Doctors, nurses, healthcare professionals, holistic practitioners, Yoga students and many people who have never practiced any healing before - attending Karina’s Quantum-Touch workshops?</p>
            <p>Because…</p>
            <p>Quantum-Touch is producing the most remarkable results many have ever seen in any healing modality yet - with no experience necessary.</p>
            
            <StyledBlockQuote>
              <Icon name="singlequote" />
              <p>I have been to so many workshops and <strong>the Quantum-Touch workshops run by Karina are the BEST by far</strong>. She has a wonderful natural style and radiates enthusiasm, knowledge and beautiful energy. So whether you are a novice or a seasoned practitioner or therapist you will gain so much from any of the workshops Karina runs.</p>
              <cite>Victor Kendal, UK Energy Medicine, EFT, Reiki, Reconnective healing, Quantum-Touch, Alkalizing &amp; Detox program, Scenar and Laser therapy, RIFE therapy, Energy Frequency therapy, BodyTalk, Bodytalk Access, Mindscape</cite>
            </StyledBlockQuote>

            <p>There is no physical manipulation in Quantum-Touch. Only the lightest, most gentle touch is used.</p>
            <p>The best part is that ANYONE can learn how to reduce and/or eliminate pain, whilst increasing their immune system -  in just 2 days. </p>
            <p>Whether you are an experienced holistic practitioner, nurse, Doctor, parent, Yoga student or have never learned any energy healing before - <strong>this workshop will absolutely transform your ability to help yourself and others in a way that will blow your mind.</strong></p>
            
            <StyledBlockQuote ref={ref}>
              <Icon name="singlequote" />
              <p>I attended the workshop having suffered excruciating shoulder, neck and jaw joint (TMJ) pain following a very serious motorway accident 8 years ago, resulting in the loss of a 21 year aviation career, an expensive and painful failed operation and chronic, localised osteoarthritis from the shoulders up.</p>
  
              <p>During Karina’s Quantum-Touch workshop, the pain COMPLETELY disappeared. For the first time in 8 years I was totally pain free and had no need to take my 6 hourly prescription pain relief. I was buzzing like a light bulb the whole weekend.</p>

              <p>I could not recommend this enough. No doctor or surgeon has achieved this except with a general anaesthetic.</p>
  
              <p>I now have total expectation of once again leading a pain free and normal life, so thank you so very much.</p>
              <cite>C. Lambert West Sussex</cite>
              
            </StyledBlockQuote>
            <GatsbyImage
                image={qtBook.imageData}
                alt={qtBook.alt}
                width="100%"
                padding-bottom="0" />
          </StyledAboutLander>
          <AboutLanderContent>
            {/* <div className="inview-wrapper" ref={ref}> */}
            <h2>What is Quantum-Touch?</h2>        
            <p>Quantum-Touch is an energy healing technique that is unique in both its speed and effectiveness.</p>
            <StyledBlockQuote>
              <Icon name="singlequote" />
              <p>I am a professional musician and manager and have performed the cello all over the world with Take That, Rihanna and Ellie Goulding, and in lots of wonderful venues including the Royal Albert Hall and Buckingham Palace for the Queen. </p>
              <p>I thought my career might be over after having a child and no sleep for so long, but thanks to Karina and Quantum-Touch, I have now got it back on track and am absolutely delighted to be performing again.</p>
              <p>Quantum-Touch has changed my life in a profound way. I had never considered that any sort of energy healing could actually work and previously thought it was all probably just a bit of fun. </p>
              <p>I came into the first class as a last resort desperately looking for help to get my 2-year-old son to sleep through the night. We had seen numerous sleep consultants and doctors and had tried every gadget, sleep routine, and training available.</p>
              <p>Nothing had worked and I was completely delirious after not sleeping for more than a few hours for the past 2 years. To our amazement, my son went to sleep after only 10 minutes of Quantum-Touch, and then slept through the night!! </p>
              <p>I just couldn’t believe it! (I feel like crying with joy just remembering this moment once again!) I did the same QT techniques on him again the following night and he fell asleep after just 5 minutes. He has slept through the night ever since.</p>
              <p>We have all felt so much happier as a family since this moment and I feel I am able to finally really enjoy being a Mummy. It really is a true miracle, and I’m extremely grateful to Karina for teaching me Quantum-Touch.</p>
              <cite>Lizzy May Frazier, professional musician - London</cite>
            </StyledBlockQuote>
  
            <p>Quantum-Touch is a method of energy healing that uses very specific breathing techniques and body awareness exercises – to create a highly powerful, yet natural, field of healing energy. When the practitioner holds a high vibrational field of life-force energy around an affected area, this inspires the body’s healing process and creates a rapid healing immune response. </p>

            <StyledBlockQuote>
              <Icon name="singlequote" />
              <p>I am completely amazed with what we are capable of! I am blessed with what I learned in these two days and now I am confident to help me, my family, friends, colleagues and patients in every aspect of our lives, in a simple and effective way!</p>
              <cite>Carina Pimenta, NHS Oncology Nurse</cite>
            </StyledBlockQuote>
  
            <GatsbyImage
                image={qtImage.imageData}
                alt={qtImage.alt}
                width="100%"
                padding-bottom="0" />
            

            <p>There is no physical manipulation in Quantum-Touch. Only the lightest, most gentle touch is used.</p>
            <p>Quantum-Touch is a method of natural healing that works with the Life Force Energy of the body to promote optimal wellness. Life Force Energy, also known as “chi” in Chinese and "prana" in Sanskrit, is the flow of energy that sustains all living beings.</p>
            <p>Quantum-Touch teaches us how to focus, amplify, and specifically direct this energy, for a wide range of benefits with surprising and often extraordinary results.</p>
          
            <StyledBlockQuote>
              <Icon name="singlequote" />
              <p>I have taken the Quantum- Touch training with Karina Grant last spring. I had no experience whatsoever of running energy or healing. Karina was so inspiring on those 2 days of training that I decided to practice and take it to the next level. Since I experienced QT my life changed in a very positive way. Not only it helped on physical level but also in the way I approach life.</p>
              <p>I have been using QT since then with my family and friends. Even the most doubtful person felt that something was going on. Some experienced a change immediately, some a few days later. </p>
              <p>I highly recommend Karina who is life inspiring.</p>
              <cite>C Boulin, London PhD immunology</cite>
            </StyledBlockQuote>
  
            <p>Whether you are an experienced practitioner or brand new to energy healing - you will love learning a highly effective method for reducing back pain, realigning structure, balancing organs, glands and systems, reducing muscle aches, healing injuries, healing burns, and so much more.</p>

            <GatsbyImage
                image={backImage.imageData}
                alt={backImage.alt}
                width="100%"
                padding-bottom="0" />

            <p>Quantum-Touch is easy enough to be learned by children, yet powerful enough to astound physicians, chiropractors, physical therapists, and a host of health professionals.</p>
            <StyledBlockQuote>
              <Icon name="singlequote" />
              <p>Thanks Karina, it really was one of the best courses I have attended, and thank you to the group for helping make it that way and for healing my torn calf muscle!</p>
              <cite>Dr Rory M, MD (GP)</cite>
            </StyledBlockQuote>

            <p>The body has an extraordinary ability to heal itself and Quantum-Touch helps to maximize the body’s own capacity to heal. Quantum-Touch addresses the root cause of disease and creates the space for true healing to occur.</p>
            <p>Rather than just working on symptoms, Quantum-Touch will address the underlying cause of disease. We teach the concept of “body intelligence”: the body knows how to bring itself back to 100% wholeness given the proper conditions.</p>

            <h2>Has Quantum-Touch been tested?</h2>

            <p>Quantum-Touch has been tested in clinical settings and used in hospitals and operating rooms. It has received numerous endorsements from doctors, surgeons, nurses, chiropractors, midwives, massage therapists and many others in a wide range of fields; acupressure, Reiki, Qi Gong, Tai Chi, sports trainers and more. </p>
            
            <p>Why? </p>
            <p>Because Quantum-Touch is a safe, natural way to enhance the body’s innate ability to heal itself and it shows no limits in what can be accomplished.</p>
  
            <StyledBlockQuote>
              <Icon name="singlequote" />
              <p>Invaluable to All Health Professionals. For the researcher, the phenomenon of Quantum-Touch represents an opportunity far too important to pass up.</p>
              <cite>Professor Jim Oschman, Ph.D Author of Energy Medicine, the Scientific Basis</cite>
            </StyledBlockQuote>

            <GatsbyImage
                image={drTurnerImage.imageData}
                alt={drTurnerImage.alt}
                width="100%"
                padding-bottom="0" />

            <p>This picture shows Dr Ronnie Turner, Founder of the Irish Guild of Chiropractic. Dr Turner attended Karina’s Quantum-Touch workshops and used his chiropractic skills to accurately measure whether Quantum-Touch is effective at aligning hips and bones, as taught in the workshops.</p>
            
            <p>Dr Turner was delighted with the results and amazed at the speed and effectiveness of Quantum-Touch.</p>

            <h2>Why does Quantum-Touch work?</h2>
            <p>Your body has the unfathomable energy to heal itself. You do not have to instruct your body how to heal a cut, a bruise or a broken bone; it has the innate ability to do so. However, if your health is poor or your immune system is in some way compromised, then your body does not have the energy needed to heal itself from serious illness or injury.</p>

            <p>Quantum-Touch simply provides a powerful field of natural healing energy that your body entrains to and can draw upon to accelerate its healing.</p>

            <StyledBlockQuote>
              <Icon name="singlequote" />
              <p>In my vast experience, I’ve never seen anything to compare with the positive results of Quantum-Touch. It enabled affected team members to resume competitive play in a very brief period of time following an injury, and the improvements seemed to continue even after the therapeutic sessions.</p>
              <cite>Duane Garner, Coach UCSC Men’s Basketball Team</cite>
            </StyledBlockQuote>
            
            <h2>Does giving Quantum-Touch drain the practitioner?</h2>
            <p>The practitioner will not become drained from doing the work. Most often, the practitioner feels uplifted as a result.</p>

            <StyledBlockQuote>
              <Icon name="singlequote" />
              <p>The workshop was unbelievably wonderful and so is Karina’s teaching. There are so many materials learnt that were easy to learn. I gained lots and lots. Made me more feel more energised and feel my aches and pains less. Feel allot happier too that I came to attend this workshop. Looking forward to the next one!</p>
              <cite>Rebecca Tellias, London – Denham Unit – Ealing Hospital, NHS Trust – Registered nurse</cite>
            </StyledBlockQuote>

            <h2>A note about healing</h2>
            <p>Quantum-Touch does not claim to treat or heal specific conditions. Ultimately if the body can, it will heal itself and Quantum-Touch may accelerate that process.</p>

            <h2>Who should learn Quantum-Touch</h2>

            <p>Whether you are a complete novice, holistic practitioner or traditional health-care professional, Quantum-Touch offers you a new level of healing.</p>
            <p>Parents enjoy learning an invaluable first aid skill that is with them at all times, carers use Quantum-Touch to ease their loved ones pain, sports persons learn Quantum-Touch improve athletic performance by reducing recovery time and the list goes on. Everyone has their own reason for wanting to learn Quantum-Touch.</p>

            <p>Most people who come for Quantum-Touch sessions are so impressed with the results that they decide to learn Level 1 Quantum-Touch themselves. Family’s and partners often attend together ranging from 10 years old to 83!</p>
            
            <StyledBlockQuote>
              <Icon name="singlequote" />
              <p>Today, just 6 days after the workshop I gave some hands on healing to my dad and his frozen shoulder, within 25 minutes the pain that he has had for over 3 weeks had gone. He was skeptical and so it was a really good test for both myself and my dad. So thank you so much. Will be back for level 2 for sure.</p>
              <cite>Lorraine Robinson, UK Stable Groom</cite>
            </StyledBlockQuote>
            {/* </div> */}
            <p>Beginners enjoy a wonderful stand-alone therapy that is also effective from a distance to reduce pain, balance emotions, care for pets and so much more.</p>
            <p>Physicians, nurses, midwives, acupuncturists, Qigong practitioners, reflexologists, and other health-care professionals are consistently impressed when combining Quantum-Touch with their practice.</p>
            <ul>
              <li>Massage therapists are able to provide deeper relaxation and faster healing.</li>
              <li>Reiki Masters say Quantum-Touch dramatically improves the outcome of their sessions.</li>
              <li>Chiropractors are impressed because bones spontaneously melt into their correct alignment using a light touch.</li>
              <li>Physical therapists are enthusiastic about how rapidly Quantum-Touch reduces inflammation.</li>
            </ul>
            

            <StyledBlockQuote>
              <Icon name="singlequote" />
              <p>I’ve Quantum-Touch’d a couple of clients and they’ve melted; I can’t dispute or deny the difference Quantum-Touch is making to my work.</p>
              <cite>Kaj Meerstadt, London Massage Therapist</cite>
            </StyledBlockQuote>

          </AboutLanderContent>
          <StyledRegisterButtonWrapper isInView={inView}>
            <SingleEventRegisterButton 
              isLander="true"
              isInView={inView}
              title={data.post.title}
              excerpt={data.post.excerpt}
              content={data.post.content}
              uri={data.post.uri}
              featuredImage={data.post.featuredImage}
              datetime={data.post.eventsMetaData.datetime}
              time={data.post.eventsMetaData.time}
              timeZone={data.post.eventsMetaData.timezone}
              location={data.post.eventsMetaData.location}
              skuFullPrice={skuFull}
              skuSalePrice={skuSale}
              priceSale='265'
              price='320'
              tag='quantum-touch'
            />
          </StyledRegisterButtonWrapper>
        </div>
      </StyledLanderArticle>

      {/* <StyledEventCardLanderSection hasBackground>
        <h2>Register TODAY for my next upcoming workshop</h2>
        <SingleEventCard
          isLander="true"
          title={data.post.title}
          excerpt={data.post.excerpt}
          content={data.post.content}
          uri={data.post.uri}
          featuredImage={data.post.featuredImage}
          datetime={data.post.eventsMetaData.datetime}
          time={data.post.eventsMetaData.time}
          timeZone={data.post.eventsMetaData.timezone}
          location={data.post.eventsMetaData.location}
          skuFullPrice={skuFull}
          skuSalePrice={skuSale}
          priceSale='265'
          price='320'
          tag='quantum-touch'
        />
      </StyledEventCardLanderSection> */}

      <StyledAboutKarinaArticle>
        <AboutTeacherLanderContent>
          <h2>Meet your teacher <br /><span>Karina Grant</span></h2>
          <StyledAboutKarinaVideo>
            <ReactPlayer 
              width='100%'
              height='100%'
              className='react-player'
              url='https://karinagrant.s3.eu-west-1.amazonaws.com/videos/karinagrant-video.mp4' 
              controls='true'
              config={{ file: { attributes: { controlsList: 'nodownload' } } }}
              onContextMenu={e => e.preventDefault()}
            />
          </StyledAboutKarinaVideo>

            <StyledAboutContent itemProp="articleBody">
              {parse(aboutKarinaContent)}
            </StyledAboutContent>
        </AboutTeacherLanderContent>
      </StyledAboutKarinaArticle>

      <Testimonials data={data} isLander/> 

      {/* <StyledButton onClick={onClick}>More testimonials</StyledButton>
      { showTestimoials ? <TestimonialsMore /> : null } */}

      <HiChiMindfulnessCollectionPromo data={data.hiChiMindfulnessShopPost}/>
      
      <StyledEventCardLanderSection id="register">
        <h2>Register TODAY for my next upcoming workshop</h2>
        {/* <SingleEventCard
          isLander="true"
          title={data.post.title}
          excerpt={data.post.excerpt}
          content={data.post.content}
          uri={data.post.uri}
          featuredImage={data.post.featuredImage}
          datetime={data.post.eventsMetaData.datetime}
          time={data.post.eventsMetaData.time}
          timeZone={data.post.eventsMetaData.timezone}
          location={data.post.eventsMetaData.location}
          skuFullPrice={skuFull}
          skuSalePrice={skuSale}
          priceSale='265'
          price='320'
          tag='quantum-touch'
        /> */}
      </StyledEventCardLanderSection>
    </LayoutNoStripe>
    </>
  )
}

export default Lander1

export const pageQuery = graphql`
  query {


    wpPage(slug: { eq: "homepage" }) {
      title
      slug
      content
      testimonials {
        blockquote {
          citeLocation
          citationName
          quote
        }
        testimonialHeading
        testimonialLede
      }
    }
    aboutKarina: wpPage(slug: { eq: "about" }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      dateGmt
    }

    

    # OMNOM Event info
    post: wpEvent(id: {eq: "cG9zdDo2NjU4"}) { 
      id
      title
      content
      uri
      excerpt
      content
      featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 975
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      eventsMetaData {
        price
        priceSale
        stripefullprice
        stripesaleprice
        timezone
        location
        shortDescription
        datetime
      }
      eventsTag {
        nodes {
          slug
        }
      }
      testimonials {
        blockquote {
          citeLocation
          citationName
          quote
        }
        testimonialHeading
        testimonialLede
      }
    }

    fullPrice: allStripePrice(filter: {active: {eq: true}, id: {eq: "price_1JlbTwIfnzsCIRSOvMeUbzDt"}}) {
      edges {
        node {
          id
          active
          currency
          unit_amount
          product {
            id
            name
            active
            images
          }
        }
      }
    }

    salePrice: allStripePrice(filter: {active: {eq: true}, id: {eq: "price_1JlWRKIfnzsCIRSOLpnoRKXu"}}) {
      edges {
        node {
          id
          active
          currency
          unit_amount
          product {
            id
            name
            active
            images
          }
        }
      }
    }

    level1Data: allWpInformation(filter: {tags: {nodes: {elemMatch: {slug: {eq: "quantum-touch"}}}}, slug: {eq: "what-will-i-learn-on-a-quantum-touch-level-1-workshop"}}) {
      nodes {
        eventData {
          aboutQtLevel1
        }
      }
    }

    

    hiChiMindfulnessShopPost: allWpProduct(filter: { slug: { in: ["quantum-touch-chakra-balancing", "the-essential-mindfulness-collection", "quantum-touch-energising-morning-meditation"] } }) {
      nodes {
        title
        content
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 975
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }


    heroImage: allWpMediaItem(filter: {title: {eq: "hands-heart-world"}}) {
      nodes {
        title
        localFile {
          publicURL
        }
      }
    }

    whatsQtImage: wpMediaItem(id: {eq: "cG9zdDo2MDg3"}) {
      localFile {
        childImageSharp {
          gatsbyImageData(
            width: 975
            quality: 100
            placeholder: TRACED_SVG
            breakpoints: [320, 720, 1024, 1600]
          )
        }
      }
    }

    boneAlignmentImage: wpMediaItem(id: {eq: "cG9zdDozODAw"}) {
      localFile {
        childImageSharp {
          gatsbyImageData(
            width: 975
            quality: 100
            placeholder: TRACED_SVG
            breakpoints: [320, 720, 1024, 1600]
          )
        }
      }
    }

    drRonnieTurnerImage: wpMediaItem(id: {eq: "cG9zdDo2MDE1"}) {
      localFile {
        childImageSharp {
          gatsbyImageData(
            width: 975
            quality: 100
            placeholder: TRACED_SVG
            breakpoints: [320, 720, 1024, 1600]
          )
        }
      }
    }  

    qtBookImage: wpMediaItem(id: {eq: "cG9zdDo0NTc5"}) {
      localFile {
        childImageSharp {
          gatsbyImageData(
            width: 975
            quality: 100
            placeholder: TRACED_SVG
            breakpoints: [320, 720, 1024, 1600]
          )
        }
      }
    }   
  }
`